import React from 'react';
import Header from '../molecules/Header';
import LeftBar from '../molecules/LeftBar';

const Layout = ({ children }) => {
    const layoutStyle = {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
    };

    const contentContainerStyle = {
        display: "flex",
        flex: 1,
    };

    const contentStyle = {
        flex: 1,
        display: "flex",
        marginLeft: "80px",
    };

    return (
        <div className="layout" style={layoutStyle}>
            <Header />
            <div className="content-container" style={contentContainerStyle}>
                <LeftBar />
                <div className="content" style={contentStyle}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Layout;
