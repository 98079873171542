import React from 'react';
import AdjustableTextarea from '../atoms/AdjustableTextarea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const SubmitForm = ({ onSubmit, value, onChange, goBack }) => {

    const styles = `
    .submit-form {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    .submit-form label {
        margin-bottom: 10px;
    }
    .submit-form form {
        display: flex;
        flex-direction: column;
    }
    .submit-form .input-group {
        display: flex;
        flex-direction: column;
    }
    .submit-form button {
        background-color: #4CAF50;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
        margin-top: 20px;
        width: 60%;
        margin-left: 20%;
    }
    .submit-form button:hover {
        background-color: #0056b3;
    }
    .submit-form button:active {
        background-color: #0056b3;
    }    
    .back-button-container {
        margin-top: 20px;
    }
    .back-button {
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        background-color: #fff; 
        font-size: 1em;
    }
    .back-button:hover {
        background-color: #f2f2f2;
    }
`;

    return (
        <div className="container">
            <style>{styles}</style>
            <div className="back-button-container">
                <button onClick={goBack} className="back-button">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                </button>
            </div>
            <div className="submit-form">
                <label htmlFor="comments">Comments</label>
                <form onSubmit={onSubmit}>
                    <div className="input-group">
                        <AdjustableTextarea value={value} onChange={onChange} />
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SubmitForm;