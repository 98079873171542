import { CosmosClient } from '@azure/cosmos';

// Azure Cosmos DB configuration
const endpoint = process.env.REACT_APP_COSMOS_DB_URI;
const key = process.env.REACT_APP_COSMOS_DB_KEY;
const databaseId = process.env.REACT_APP_COSMOS_DB_ID;
const containerId = 'firstapprovers';

const client = new CosmosClient({ endpoint, key });

const database = client.database(databaseId);
const container = database.container(containerId);

const insertFirstAppover = async (firstApproverData) => {
    try {
        const { resource } = await container.items.create(firstApproverData);
        return resource;
    } catch (error) {
        console.error('Error inserting firstApprover data:', error);
        throw error;
    }
};

const getFirstApprovers = async () => {
    try {
        const { resources } = await container.items.readAll().fetchAll();
        return resources;
    } catch (error) {
        console.error('Error getting vendors:', error);
        throw error;
    }
};

const checkFirstApproverExist = async (firstApprover) => {
    try {
        const { resources } = await container.items.query({
            query: `SELECT * FROM c WHERE c.email = "${firstApprover}"`
        }).fetchAll();
        return resources.length > 0;
    } catch (error) {
        console.error('Error getting first approver:', error);
        throw error;
    }
}

const deleteFirstApprover = async (email) => {
    try {
        const { resources } = await container.items.query({
            query: `SELECT * FROM c WHERE c.email = "${email}"`
        }).fetchAll();

        if (resources.length > 0) {
            const itemId = resources[0].id;

            // Use id for both itemId and partition key
            await container.item(itemId, itemId).delete();
        }
    } catch (error) {
        console.error('Error deleting first approver:', error.message);
        throw error;
    }
}

export { insertFirstAppover, getFirstApprovers, checkFirstApproverExist, deleteFirstApprover };
