import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const FinancialApproval = ({ value, onChange, controllerOptions }) => {

    const styles = `
    .controller-form {
        display: flex;
        flex-direction: column;
        margin-top: 100px;
        padding: 20px;
        margin-left: 50px;
        background-color: #EAFFE1;
        border-radius: 20px;
        width: 80%;
        height: 90px;
    }
    .controller-form label {
        margin-bottom: 10px;
        align-self: center;
    }
    .input-group-controller {
        display: flex;
        flex-direction: row; /* Change to column */
        margin-bottom: 20px;
    }
    .input-group-controller label {
        margin-top: 30px; /* Margin below label */
    }
    .input-group-controller .ant-select {
        width: 100%; /* Full width of container */
        height: 40px;
        margin-left: 10px; /* Margin from label */
        margin-top: 23px; /* Margin below label */
    }
    .ant-select-selector {
        border-radius: 5px !important;
        height: 40px !important;
        align-items: center !important;
        display: flex !important;
    }
`;

    return (
        <div className="controller-form">
            <style>{styles}</style>
            <div className="input-group-controller">
                <label htmlFor="controllers">Invoice Approval will send to</label>
                <Select
                    id="controllers"
                    showSearch
                    value={value}
                    onChange={onChange}
                    style={{ width: 250, fontSize: '1em', backgroundColor: '#e1eaef' }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    required
                >
                    {controllerOptions?.map((option) => (
                        <Option key={option.id} value={option.controller}>
                            {option.controller}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    );
};

export default FinancialApproval;