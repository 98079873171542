import axios from 'axios';

export const sendEmail = async (invoiceId, email, sender, vendor, senderEmail) => {
    try {
        await axios.post(`https://invoicemanagementemailer.azurewebsites.net/api/sendEmail/${invoiceId}`,
            { email, sender, vendor, senderEmail },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': process.env.REACT_APP_NODE_API_KEY,
                }
            }

        );
    } catch (error) {
        console.error('Error sending email:', error);
    }
};

export const sendEmailApproval = async (invoiceId, email, sender, vendor, senderEmail) => {
    try {
        await axios.post(`https://invoicemanagementemailer.azurewebsites.net/api/sendEmailApproval/${invoiceId}`,
            { email, sender, vendor, senderEmail },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': process.env.REACT_APP_NODE_API_KEY,
                }
            }

        );
    } catch (error) {
        console.error('Error sending email:', error);
    }
};

export const deletePdfNode = async (accessToken, fileName) => {
    try {
        const response = await axios.delete('https://invoicemanagementemailer.azurewebsites.net/api/delete', {
            params: {
                accessToken: accessToken,
                fileName: fileName
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting PDF:', error);
        throw new Error('Failed to delete PDF');
    }
};

export const sendEmailPaid = async (invoiceId, email, vendor, senderEmail, paidBy) => {

    try {
        await axios.post(`https://invoicemanagementemailer.azurewebsites.net/api/sendEmailPaid/${invoiceId}`,
            { email, vendor, senderEmail, paidBy },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': process.env.REACT_APP_NODE_API_KEY,
                }
            }

        );
    } catch (error) {
        console.error('Error sending email:', error);
    }
};

export const sendEmailReminder = async (email, senderEmail) => {
    try {
        await axios.post(`https://invoicemanagementemailer.azurewebsites.net/api/sendEmailReminder`,
            { email, senderEmail },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': process.env.REACT_APP_NODE_API_KEY,
                }
            }

        );
    } catch (error) {
        console.error('Error sending email:', error);
    }
};