import axios from 'axios';

export const extractInvoice = async (file) => {
    const payload = {
        apiKey: 'AOOERIOVNSDJLKFNOITRJKFSVKLSDFKLSDFG4',
        file: file
    };

    try {
        const res = await axios.post('https://f2olr4qxa7.execute-api.eu-west-2.amazonaws.com/default/invoiceParserWeSoda', payload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return res.data;
    } catch (error) {
    }
};