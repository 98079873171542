import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFPreview = React.memo(({ pdfFile }) => {

    const styles = `
        .pdf-document {
        height: 100vh; /* Full viewport height */
        max-width: 40vw; /* Full viewport width */
        overflow: hidden;
        }
        .pdf-page {
        height: 100%;
        width: auto;
        max-width: 100%;
        object-fit: contain;
        }
`;

    return (
        <div>
            <style>{styles}</style>
            {pdfFile && (
                <embed src={URL.createObjectURL(new Blob([pdfFile], { type: 'application/pdf' }))} width="200%" height="500" />
            )}
        </div>
    );
});

export default PDFPreview;
