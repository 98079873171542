import React, { useState, useEffect } from 'react';
import { Table, Tag, Input, Button, Spin, Checkbox, Dropdown, Menu, Select, Modal } from 'antd';
import { getInvoices, getInvoicesPendingApproval, getInvoicesFirstApproval } from '../../api/InvoiceContainer';
import { getVendors, getVendorTypes } from '../../api/VendorsContainer';
import { useNavigate } from 'react-router-dom'; // Import useHistory hook from React Router
import { useAuth } from '../../context/AuthContext';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

const { Search } = Input;
const { Option } = Select;

const InvoiceHistory = () => {
    const [searchText, setSearchText] = useState('');
    const [sortedInfo, setSortedInfo] = useState({});
    const [invoiceData, setInvoiceData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visibleColumns, setVisibleColumns] = useState([
        'company', 'vendor', 'invoice_number',
        'invoice_date', 'due_date', 'amount', 'currency',
        'sender', 'pending', 'status', 'agreement'
    ]);
    const navigate = useNavigate();
    const { role, user } = useAuth();
    const [exportModalVisible, setExportModalVisible] = useState(false);
    const [vendorType, setVendorType] = useState('All');

    const [vendorTypeOptions, setVendorTypeOptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const vendorTypes = await getVendorTypes();
            setVendorTypeOptions(vendorTypes);
        };
        fetchData();
    }, []);

    const handleVendorTypeChange = (value) => {
        setVendorType(value);
    };

    useEffect(() => {
        const fetchData = async () => {
            let data = null;
            if (role === 'Sender') {
                data = await getInvoices(user.name);
            }
            else if (role === 'Admin') {
                data = await getInvoices();
            }
            else if (role === 'Financial Controller') {
                data = await getInvoices();
            }
            else if (role === 'First Approver') {
                data = await getInvoicesFirstApproval(user.name);
            }
            else if (role === 'Sender or Accountant') {
                data = await getInvoices();
            }

            const vendorData = await getVendors();
            const vendorMap = vendorData?.reduce((acc, vendor) => {
                acc[vendor.id] = vendor.vendor;
                return acc;
            }, {});

            const formattedData = data?.map((invoice) => {
                const company = invoice.company || 'We Soda Ltd';

                // Format invoice_date
                const invoiceDate = new Date(invoice.invoice_date);
                const formattedInvoiceDate = `${invoiceDate.getDate()}/${invoiceDate.getMonth() + 1}/${invoiceDate.getFullYear()}`;

                // Format due_date if it exists
                let formattedDueDate = '';
                if (invoice?.due_date) {
                    const dueDate = new Date(invoice?.due_date);
                    formattedDueDate = `${dueDate.getDate()}/${dueDate.getMonth() + 1}/${dueDate.getFullYear()}`;
                }

                if (invoice?.paid_date) {
                    const paidDate = new Date(invoice?.paid_date);
                    invoice.paid_date = `${paidDate.getDate()}/${paidDate.getMonth() + 1}/${paidDate.getFullYear()}`;
                }

                // Format amount
                const currencyCode = invoice?.currency === 'TL' ? 'TRY' : invoice?.currency === 'EURO' ? 'EUR' : 'GBP';
                const formattedAmount = parseFloat(invoice?.amount).toLocaleString('en-US', {
                    style: 'currency',
                    currency: currencyCode,
                });

                const invoiceType = vendorData.find(vendor => vendor.id === invoice.vendor_id)?.type;
                invoice.invoice_type = invoiceType;

                let pendingApproval = '';

                if (invoice?.status === 'Pending First Approval' && invoice?.first_approver !== null) {
                    pendingApproval = invoice?.first_approver;
                }
                if (invoice?.status === 'Pending') {
                    pendingApproval = 'Jim Yow';
                }

                if (invoice?.status === 'Approved') {
                    pendingApproval = invoice?.approved_by
                }

                if (invoice?.status === 'Rejected') {
                    pendingApproval = invoice?.rejected_by
                }
                if (invoice?.status === 'Paid') {
                    pendingApproval = invoice?.paid_by
                }

                return {
                    ...invoice,
                    company,
                    currencyCode,
                    vendor: vendorMap[invoice?.vendor_id],
                    invoice_date: formattedInvoiceDate,
                    due_date: formattedDueDate,
                    amount: formattedAmount,
                    pending: pendingApproval,
                    invoice_type: invoiceType,
                    comment: invoice?.sender_comment,
                };
            });

            // sort the data by status Pending first 
            formattedData?.sort((a, b) => {
                if (a.status === 'Pending') {
                    return -1;
                }
                if (b.status === 'Pending') {
                    return 1;
                }
                if (a.status === 'Pending First Approval') {
                    return -1;
                }
                if (b.status === 'Pending First Approval') {
                    return 1;
                }
                if (a.status === 'Approved') {
                    return -1;
                }
                if (b.status === 'Approved') {
                    return 1;
                }
                if (a.status === 'Paid') {
                    return -1;
                }
                if (b.status === 'Paid') {
                    return 1;
                }

                return 0;
            });

            setInvoiceData(formattedData);
        };
        if (user) {
            fetchData()
                .then(() => setLoading(false));
        }
    }, [role, user]);

    const styles = `
    .container {
        margin-top: 30px;
        flex: 1;
        margin-left: -60px;
    }
    .ant-table-row:hover {
        cursor: pointer; 
    }
    `;
    const tableStyles = {
        marginTop: 20,
        width: '100%',
    };

    const handleInvoiceClick = (invoice) => {
        if (role === 'Financial Controller' && invoice.status === 'Pending First Approval') {
            return;
        }
        navigate(`/invoice-detail/${invoice.id}`);
    };

    const allColumns = [
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            sorter: (a, b) => a.company.localeCompare(b.company),
            sortOrder: sortedInfo.columnKey === 'company' && sortedInfo.order,
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor',
            key: 'vendor',
            sorter: (a, b) => a.vendor.localeCompare(b.vendor),
            sortOrder: sortedInfo.columnKey === 'vendor' && sortedInfo.order,
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
            sortOrder: sortedInfo.columnKey === 'invoice_number' && sortedInfo.order,
        },
        {
            title: 'Invoice Type',
            dataIndex: 'invoice_type',
            key: 'invoice_type',
            sorter: (a, b) => {
                const invoiceTypeA = a.invoice_type || ''; // If invoice_type is undefined, use an empty string
                const invoiceTypeB = b.invoice_type || ''; // If invoice_type is undefined, use an empty string
                return invoiceTypeA.localeCompare(invoiceTypeB);
            },
            sortOrder: sortedInfo.columnKey === 'invoice_type' && sortedInfo.order,
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            sorter: (a, b) => a.invoice_date?.localeCompare(b.invoice_date),
            sortOrder: sortedInfo.columnKey === 'invoice_date' && sortedInfo.order,
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            sorter: (a, b) => a?.due_date?.localeCompare(b?.due_date),
            sortOrder: sortedInfo.columnKey === 'due_date' && sortedInfo.order,
        },
        {
            title: 'Paid Date',
            dataIndex: 'paid_date',
            key: 'paid_date',
            sorter: (a, b) => a?.paid_date?.localeCompare(b?.paid_date),
            sortOrder: sortedInfo.columnKey === 'paid_date' && sortedInfo.order,
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            sorter: (a, b) => a.currency.localeCompare(b.currency),
            sortOrder: sortedInfo.columnKey === 'currency' && sortedInfo.order,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => {
                // Remove currency symbols and commas from the amount strings and parse as float
                const amountA = parseFloat((a.amount || '').replace(/[^0-9.-]+/g, '')); // Get the number value from amount A
                const amountB = parseFloat((b.amount || '').replace(/[^0-9.-]+/g, '')); // Get the number value from amount B

                // Check for NaN or undefined values
                if (isNaN(amountA) || isNaN(amountB) || amountA === undefined || amountB === undefined) {
                    return 0; // Return 0 if amounts are NaN or undefined
                }

                return amountA - amountB;
            },
            sortOrder: sortedInfo.columnKey === 'amount' && sortedInfo.order,
        },
        {
            title: 'Sender',
            dataIndex: 'sender',
            key: 'sender',
            sorter: (a, b) => a.sender.localeCompare(b.sender),
            sortOrder: sortedInfo.columnKey === 'sender' && sortedInfo.order,
        },
        {
            title: 'Pending/Approved',
            dataIndex: 'pending',
            key: 'pending',
            sorter: (a, b) => a.pending?.localeCompare(b.pending),
            sortOrder: sortedInfo.columnKey === 'pending' && sortedInfo.order,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                const color = tagColorHandler(status);
                return (
                    <Tag color={color} key={status}>
                        {status.toUpperCase()}
                    </Tag>
                );
            },
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
        },
        {
            title: 'Agreement',
            dataIndex: 'agreement',
            key: 'agreement',
            sorter: (a, b) => a.agreement?.localeCompare(b.agreement),
            sortOrder: sortedInfo.columnKey === 'agreement' && sortedInfo.order,
        }
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter); // Update sortedInfo state with the current sorting information
    };

    const tagColorHandler = (tag) => {
        switch (tag) {
            case 'Pending':
                return 'orange';
            case 'Approved':
                return 'green';
            case 'Rejected':
                return 'red';
            case 'Pending First Approval':
                return 'blue';
            case 'Paid':
                return 'purple';
            default:
                return 'default';
        }
    };

    const filteredData = invoiceData?.filter((invoice) =>
        Object.values(invoice).some((value) => {
            if (value === null || value === undefined) {
                return false; // Skip null or undefined values
            }
            return value.toString().toLowerCase().includes(searchText.toLowerCase());
        })
    );

    const exportToCSV = () => {
        // Map your filtered data to CSV columns
        const csvData = filteredData.map(row => ({
            Company: row.company,
            Vendor: row.vendor,
            'Invoice Number': row.invoice_number,
            'Invoice Type': row.invoice_type,
            'Invoice Date': row.invoice_date,
            'Due Date': row.due_date,
            'Paid Date': row.paid_date,
            Amount: // Remove currency symbols
                parseFloat((row.amount || '').replace(/[^0-9.-]+/g, '')) || 0, // Parse as float or default to 0
            Currency: row.currency,
            Sender: row.sender,
            Comment: row.comment,
            'Pending': row.pending_approval,
            Status: row.status,
            Agreement: row.agreement,
        }));

        // Use PapaParse to convert data to CSV format
        const csv = Papa.unparse(csvData);

        // Create a Blob object with CSV content and set UTF-8 encoding
        const blob = new Blob([csv], { type: 'data:text/csv;charset=utf-8,%EF%BB%BF;' });

        // Generate a download link and trigger download programmatically
        const today = new Date().toISOString().split('T')[0];
        saveAs(blob, `invoices-${today}.csv`);
    };

    const handleExport = () => {
        if (vendorType === 'All') {
            exportToCSV();
        } else {
            const filteredDataByVendorType = filteredData.filter(invoice => invoice.invoice_type === vendorType);

            // Map your filtered data to CSV columns
            const csvData = filteredDataByVendorType.map(row => ({
                Company: row.company,
                Vendor: row.vendor,
                'Invoice Number': row.invoice_number,
                'Invoice Type': row.invoice_type,
                'Invoice Date': row.invoice_date,
                'Due Date': row.due_date,
                'Paid Date': row.paid_date,
                Amount: // Remove currency symbols
                    parseFloat((row.amount || '').replace(/[^0-9.-]+/g, '')) || 0, // Parse as float or default to 0
                Currency: row.currency,
                Sender: row.sender,
                Comment: row.comment,
                'Pending': row.pending_approval,
                Status: row.status,
                Agreement: row.agreement,
            }));

            // Use PapaParse to convert data to CSV format
            const csv = Papa.unparse(csvData);

            // Create a Blob object with CSV content and set UTF-8 encoding
            const blob = new Blob([csv], { type: 'data:text/csv;charset=utf-8,%EF%BB%BF;' });

            // Generate a download link and trigger download programmatically
            const today = new Date().toISOString().split('T')[0];
            saveAs(blob, `invoices-${vendorType}-${today}.csv`);
        }
    };


    const handleColumnChange = (checkedValues) => {
        setVisibleColumns(checkedValues);
        //
    };

    const columnCheckboxOptions = allColumns.map(column => ({
        label: column.title,
        value: column.key
    }));

    if (loading) {
        return <Spin
            size="large"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flex: 1, marginTop: -100 }}
        />;
    }

    return (
        <div className="container">
            <style>{styles}</style>
            <Search
                placeholder="Search invoices"
                style={{ width: 200, marginBottom: 16 }}
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText} // Add this line to reflect the current value of searchText in the input
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16, right: 0 }}>
                <Button type="primary" onClick={() => setExportModalVisible(true)}>
                    Export to CSV
                </Button>
                <Dropdown
                    overlay={
                        <Menu>
                            <Checkbox.Group
                                options={columnCheckboxOptions}
                                defaultValue={visibleColumns}
                                onChange={handleColumnChange}
                            />
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <Button>Show/Hide Columns</Button>
                </Dropdown>
            </div>
            <Table
                style={tableStyles}
                columns={allColumns.filter(column => visibleColumns.includes(column.key))}
                dataSource={filteredData}
                onChange={handleTableChange}
                onRow={(record) => ({ onClick: () => handleInvoiceClick(record) })}
                rowKey="id"
            />
            <Modal
                title="CSV Export"
                open={exportModalVisible}
                onOk={() => { handleExport(); setExportModalVisible(false); }}
                onCancel={() => setExportModalVisible(false)}
            >
                <p>Select vendor type to export invoices.</p>
                <Select
                    id="vendorType"
                    showSearch  // Enable search functionality
                    value={vendorType}
                    onChange={handleVendorTypeChange}
                    style={{ width: 250, height: 40, fontSize: '1.2em', backgroundColor: '#e1eaef' }}
                    placeholder="Select Vendor Type"
                    optionFilterProp="children"  // Specify the property to filter options on
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }  // Filter options based on input
                    required
                >
                    {vendorTypeOptions.map((option) => (
                        <Option key={option.id} value={option.type}>
                            {option.type}
                        </Option>
                    ))}
                </Select>

            </Modal>
        </div>
    );
};

export default InvoiceHistory;
