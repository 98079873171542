import React from 'react';

const StepIndicator = ({ activeTab }) => {
    const styles = `
    .step-container {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        justify-content: space-between; 
    }
    .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 10px;
    }
    .circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #f2f2f2; /* Light grey background color */
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 1.2em;
    }
    .circleActive {
        background-color: #007bff; /* Blue background color */
        color: #fff;
    }
    .step-label {
        margin-top: 10px;
    }
    .line {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #000; /* Light grey background color */
        margin-top: 20px;
    }
`;



    return (
        <div className="step-container">
            <style>{styles}</style>
            <div className="step">
                <div className={activeTab === 'upload' ? 'circle circleActive' : 'circle'}>1</div>
                <label className="step-label">Upload</label>
            </div>
            <div className="line">
                <hr />
            </div>
            <div className="step">
                <div className={activeTab === 'submit' ? 'circle circleActive' : 'circle'}>2</div>
                <label className="step-label">Submit</label>
            </div>
        </div>
    );
};

export default StepIndicator;
