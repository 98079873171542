import { CosmosClient } from '@azure/cosmos';

// Azure Cosmos DB configuration
const endpoint = process.env.REACT_APP_COSMOS_DB_URI;
const key = process.env.REACT_APP_COSMOS_DB_KEY;
const databaseId = process.env.REACT_APP_COSMOS_DB_ID;
const containerId = 'users';

const client = new CosmosClient({ endpoint, key });

const database = client.database(databaseId);
const container = database.container(containerId);

const insertUser = async (userData) => {
    try {
        const { resource } = await container.items.create(userData);
        return resource;
    } catch (error) {
        console.error('Error inserting user data:', error);
        throw error;
    }
};

const getUsers = async () => {
    try {
        const { resources } = await container.items.readAll().fetchAll();
        return resources;
    } catch (error) {
        console.error('Error getting users:', error);
        throw error;
    }
};

const checkUserExist = async (email) => {
    try {
        const querySpec = {
            query: 'SELECT * FROM c WHERE c.email = @email',
            parameters: [{ name: '@email', value: email }],
        };

        const { resources } = await container.items.query(querySpec).fetchAll();
        return resources.length > 0;
    } catch (error) {
        console.error('Error checking user:', error);
        throw error;
    }
};

const getUser = async (email) => {
    try {
        const querySpec = {
            query: 'SELECT * FROM c WHERE c.email = @email',
            parameters: [{ name: '@email', value: email }],
        };

        const { resources } = await container.items.query(querySpec).fetchAll();
        return resources[0];
    } catch (error) {
        console.error('Error getting user:', error);
        throw error;
    }
};

const getUserById = async (userId) => {
    try {
        const { resource } = await container.item(userId).read();
        return resource;
    } catch (error) {
        console.error('Error getting user:', error);
        throw error;
    }
};

const updateUser = async (userId, role) => {
    try {
        const user = await getUserById(userId);
        user.role = role;
        const { resource } = await container.item(userId).replace(user);
        return resource;

    } catch (error) {
        console.error('Error updating user:', error);
        throw error;
    }
};

export { insertUser, getUsers, checkUserExist, updateUser, getUser };