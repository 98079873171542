import React, { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import VendorForm from '../molecules/VendorForm';
import VendorList from '../molecules/VendorList';
import { insertVendor, updateVendor } from '../../api/VendorsContainer';

const Vendor = () => {
    const [loading, setLoading] = useState(false);
    const [vendor, setVendor] = useState('');
    const [type, setType] = useState('');
    const [recurring, setRecurring] = useState(false);
    const [updatedVendor, setUpdatedVendor] = useState(null);

    const styles = `
    .vendor-container {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    .vendor-creation-container {
        flex: 1; /* Take up remaining space vertically */
        margin-top: -40px;
        margin-left: -50px;
        width: 100%;
    }
    .vendor-list-container {
        flex: 1; /* Take up remaining space vertically */
        margin-top: -20px;
        margin-left: -20px;
        width: 100%;
    }
    .h1 {
        margin-left: 20px;
        margin-top: 20px;
        align-self: center;
        justify-self: center;
    }
`;

    const handleVendorSubmit = async () => {
        setLoading(true);
        if (updatedVendor) {
            await updateVendor(updatedVendor.id, vendor, type, recurring);
            message.success('Vendor updated successfully');
            setUpdatedVendor(null);
            setVendor('');
            setType('');
            setRecurring(false);
            setLoading(false);
            return;
        }
        await insertVendor({ vendor, type, recurring });
        message.success('Vendor created successfully');
        setVendor('');
        setType('');
        setRecurring(false);
        setLoading(false);
    };

    const handleVendorClick = (record) => {
        setUpdatedVendor(record);
    };

    useEffect(() => {
        if (updatedVendor) {
            setVendor(updatedVendor?.vendor);
            setType(updatedVendor?.type || '');
            setRecurring(updatedVendor?.recurring);
        }
    }, [updatedVendor]);

    if (loading) {
        return <Spin
            size="large"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flex: 1, marginTop: -100 }}
        />;
    }

    return (
        <div className='vendor-container'>
            <style>{styles}</style>
            {loading && <Spin size='large' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flex: 1, marginTop: -100 }} />}
            <div className='vendor-creation-container'>
                <VendorForm
                    vendor={vendor}
                    type={type}
                    recurring={recurring}
                    handleVendorChange={e => setVendor(e.target.value)}
                    handleTypeChange={e => setType(e.target.value)}
                    handleRecurringChange={e => setRecurring(e.target.checked)}
                    handleSubmit={handleVendorSubmit}
                    isUpdate={updatedVendor !== null}
                />
            </div>
            <div className='vendor-list-container'>
                <VendorList handleVendorClick={handleVendorClick} />
            </div>
        </div>
    );
};

export default Vendor;
