import React, { useEffect, useState } from 'react';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Spin, Modal, DatePicker } from 'antd';
import { useNavigate, useParams } from 'react-router-dom'; // Import useHistory hook from React Router
import PdfViewer from '../molecules/PdfViewer';
import ApproveSubmitForm from '../molecules/ApproveSubmitForm';
import {
    getInvoiceById,
    approveInvoice,
    updateInvoice,
    deleteInvoice,
    rejectInvoice,
    approveInvoiceFirstApprover,
    markInvoiceAsPaid
} from '../../api/InvoiceContainer';
import { useAuth } from '../../context/AuthContext';
import { moveFileFromOneDrive, deletePdfFromOneDrive, moveFileToRejectedFolder, moveFileToPaidFolder } from '../../api/PdfUpload';
import InvoiceForm from '../molecules/InvoiceForm';
import { getVendors, getCompanies } from '../../api/VendorsContainer';
import dayjs from 'dayjs';
import { Form, Button, message } from 'antd';
import { folderVariables, folderVariablesSodaWorld } from '../../api/oneDriveConfig';
import { sendEmail, sendEmailApproval, sendEmailPaid } from '../../api/NodeServer';
import moment from 'moment';

// GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.1.392/pdf.worker.min.mjs`;

const InvoiceDetail = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [comments, setComments] = useState('');
    const [firstApproverComment, setFirstApproverComment] = useState('');
    const [sender, setSender] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [senderValue, setSenderValue] = useState('');
    const [status, setStatus] = useState('');
    const [sendingDate, setSendingDate] = useState('');
    const [approvalDate, setApprovalDate] = useState('');
    const [firstApproverApprovalDate, setFirstApproverApprovalDate] = useState('');
    const [firstApprover, setFirstApprover] = useState('');
    const [fileName, setFileName] = useState('');
    const navigate = useNavigate();
    const { accessToken, role, user } = useAuth();
    const [vendors, setVendors] = useState([]);
    const [vendor, setVendor] = useState('');
    const [company, setCompany] = useState('');
    const [companies, setCompanies] = useState([]);
    const [invoiceDate, setInvoiceDate] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('');
    const [vendorId, setVendorId] = useState('');
    const [checkBoxValue, setCheckBoxValue] = useState(false);
    const [paidModalVisible, setPaidModalVisible] = useState(false);
    const [paidDate, setPaidDate] = useState(dayjs());

    const goBack = () => {
        navigate('/invoice-history');
    };

    useEffect(() => {
        const fetchInvoice = async () => {
            setLoading(true);
            const invoice = await getInvoiceById(id);
            setSender(invoice.sender);
            setSenderEmail(invoice.sender_email);
            setSenderValue(invoice.sender_comment);
            setComments(invoice.approval_comment);
            setFirstApproverComment(invoice.first_approval_comment);
            setFirstApprover(invoice.first_approver);
            setStatus(invoice.status);
            setFileName(invoice.file_name);
            const date = new Date(invoice.sender_date);
            const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            setSendingDate(formattedDate);
            if (invoice.approved_date) {
                const approvalDate = new Date(invoice.approved_date);
                const formattedApprovalDate = `${approvalDate.getDate()}/${approvalDate.getMonth() + 1}/${approvalDate.getFullYear()}`;
                setApprovalDate(formattedApprovalDate);
            }
            if (invoice.first_approved_date) {
                const approvalDate = new Date(invoice.first_approved_date);
                const formattedApprovalDate = `${approvalDate.getDate()}/${approvalDate.getMonth() + 1}/${approvalDate.getFullYear()}`;
                setFirstApproverApprovalDate(formattedApprovalDate);
            }
            setCompany(invoice.company);
            setCheckBoxValue(invoice.agreement === 'Approved with Agreement' ? true : false);
            //console.log(invoice.agreement === 'Approved with Agreement' ? true : false)
            setLoading(false);
        };

        fetchInvoice();

        return () => {
            // Reset state when unmounting
            setComments('');
            setFirstApproverComment('');
            setSender('');
            setSenderValue('');
            setStatus('');
        };
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getInvoiceById(id);
            if (data.invoice_date) {
                setInvoiceDate(dayjs(data.invoice_date));
            }
            else {
                setInvoiceDate('');
            }

            if (data.due_date) {
                setDueDate(dayjs(data.due_date));
            }
            else {
                setDueDate('');
            }

            setInvoiceNumber(data.invoice_number);
            setAmount(data.amount);
            setCurrency(data.currency);
            setVendorId(data.vendor_id);
            setCompany(data.company);
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        const fetchVendors = async () => {
            const data = await getVendors();
            setVendors(data);

            const vendor = data?.find((vendor) => vendor.id === vendorId);
            setVendor(vendor?.vendor);
        };

        fetchVendors();

    }, [vendorId]);

    useEffect(() => {
        const fetchCompanies = async () => {
            const data = await getCompanies();
            setCompanies(data);
        };

        fetchCompanies();

    }, [company]);

    const handleFormSubmit = () => {
        setLoading(true);

        if (status === 'Pending First Approval') {
            approveInvoiceFirstApprover(id, user.name, firstApproverComment, 'Pending')
                .then(() => {
                    setLoading(false);
                    // send email to real financial controller
                    sendEmail(id, 'jim.yow@wesoda.com', user?.name, vendor, user?.username);
                    navigate('/invoice-history');
                });
        }
        else if (status === 'Pending' && (role === 'Admin' || role === 'Financial Controller')) {
            moveFileFromOneDrive(accessToken, fileName, company)
                .then(() => {
                    approveInvoice(id, user.name, comments, 'Approved', checkBoxValue)
                        .then(() => {
                            const companyFolder = company === 'We Soda Ltd' ? folderVariables : company === 'Soda World Ltd' ? folderVariablesSodaWorld : folderVariables

                            deletePdfFromOneDrive(accessToken, companyFolder.invoiceFolder, fileName);
                            // send email to emrah
                            sendEmailApproval(id, 'emrah.aydin@wesoda.com', user?.name, vendor, user?.username);
                            setLoading(false);
                            navigate('/invoice-history');
                        });
                });
        }
    }

    const handleVendorChange = (newVendor) => {
        setVendor(newVendor);
    };

    const handleCompanyChange = (newCompany) => {
        setCompany(newCompany);
    };

    const handleInvoiceNumberChange = (e) => {
        setInvoiceNumber(e.target.value);
    }

    const handleInvoiceDateChange = (date) => {
        setInvoiceDate(date);
    }

    const handleDueDateChange = (date) => {
        setDueDate(date);
    }

    const handleCurrencyChange = (value) => {
        setCurrency(value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    }

    const handleSubmit = async () => {
        setLoading(true);
        // Update the invoice with the new values
        const invoiceData = {
            company: company,
            invoice_date: invoiceDate,
            due_date: dueDate,
            invoice_number: invoiceNumber,
            amount: amount,
            currency: currency,
            vendor_id: vendors.find((v) => v.vendor === vendor).id,
        };

        await updateInvoice(id, invoiceData);
        setLoading(false);

        message.success('Invoice updated successfully');
    };

    const handleDelete = async () => {
        setLoading(true);
        await deleteInvoice(id);
        // check status

        const companyFolder = company === 'We Soda Ltd' ? folderVariables : company === 'Soda World Ltd' ? folderVariablesSodaWorld : folderVariables
        const folder = status === 'Approved' ? companyFolder.invoiceApprovalFolder : status === 'Paid' ? companyFolder.invoicePaidFolder : companyFolder.invoiceFolder;

        await deletePdfFromOneDrive(accessToken, folder, fileName);
        setLoading(false);
        message.success('Invoice deleted successfully');
        navigate('/invoice-history');
    };

    const handleCheckBoxChange = (e) => {
        setCheckBoxValue(e.target.checked);
    };

    const handleReject = async () => {
        setLoading(true);
        if (status === 'Pending First Approval') {
            rejectInvoice(id, user.name, firstApproverComment, 'Rejected')
        }
        else if (status === 'Pending' && (role === 'Admin' || role === 'Financial Controller')) {
            rejectInvoice(id, user.name, comments, 'Rejected')
        }

        const companyFolder = company === 'We Soda Ltd' ? folderVariables : company === 'Soda World Ltd' ? folderVariablesSodaWorld : folderVariables
        const folder = status === 'Approved' ? companyFolder.invoiceApprovalFolder : companyFolder.invoiceFolder;

        await moveFileToRejectedFolder(accessToken, fileName, company, status)
            .then(() => {
                deletePdfFromOneDrive(accessToken, folder, fileName)
                    .then(() => {
                        setLoading(false);
                        navigate('/invoice-history');
                    });
            });
    };

    const handleMarkAsPaid = async () => {
        setLoading(true);
        await markInvoiceAsPaid(id, user.name, 'Paid', paidDate)
            .then(() => {
                const companyFolder = company === 'We Soda Ltd' ? folderVariables : company === 'Soda World Ltd' ? folderVariablesSodaWorld : folderVariables
                const folder = status === 'Approved' ? companyFolder.invoiceApprovalFolder : companyFolder.invoiceFolder;

                moveFileToPaidFolder(accessToken, fileName, company, status);
                deletePdfFromOneDrive(accessToken, folder, fileName);

                sendEmailPaid(id, senderEmail, vendor, user?.username, user?.name);

                setLoading(false);
                navigate('/invoice-history');
            });

    }

    const styles = `
    .container {
        display: flex;
        flex-direction: row;
        padding: 20px;
    }
    .pdf-preview-container {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-left: -50px;
        flex: 1;
        width: 600px;
    }
    .back-button-container {
        margin-top: 20px;
        margin-left: -80px;
    }
    .back-button {
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        background-color: #fff; 
        font-size: 1em;
    }
    .back-button:hover {
        background-color: #f2f2f2;
    }
    .delete-button {
        width: 10%;
        position: absolute;
        top: 15%;
        margin-left: 60%;
        transform: translate(-50%, -50%);
    }
`;

    const datePickerStyle = {
        width: "100%",
        height: "40px",
        fontSize: "1.2em",
        borderRadius: "5px",
        border: "1px solid #ccc",
        padding: "10px",
        boxSizing: "border-box",
        marginTop: "30px"
    };

    if (sender === '' || loading) {
        return <Spin
            size="large"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flex: 1, marginTop: -100 }}
        />;
    }

    return (
        <div>
            <style>{styles}</style>
            <div className="back-button-container">
                <button onClick={goBack} className="back-button">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                </button>
            </div>
            <div className='container'>
                <div className='pdf-preview-container'>
                    <PdfViewer accessToken={accessToken} fileName={fileName} status={status} company={company} />
                </div>
                {((status === 'Approved' || status === 'Paid') || ((status === 'Pending' || status === 'Pending First Approval') && (role === 'Admin' || role === 'Financial Controller' || role === 'First Approver' || role === 'Sender or Accountant'))) && (
                    <ApproveSubmitForm
                        sender={sender}
                        senderValue={senderValue}
                        status={status}
                        sendingDate={sendingDate}
                        approvalDate={approvalDate}
                        onSubmit={handleFormSubmit}
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        firstApproverComment={firstApproverComment}
                        setFirstApproverComment={(e) => setFirstApproverComment(e.target.value)}
                        firstApprover={firstApprover}
                        firstApproverCommentDate={firstApproverApprovalDate}
                        checkBoxValue={checkBoxValue}
                        onCheckBoxChange={handleCheckBoxChange}
                        goBack={goBack}
                        role={role}
                        onReject={handleReject}
                        onPay={() => setPaidModalVisible(true)}
                    />
                )}
                {role === 'Sender' && status === 'Pending' && (
                    <InvoiceForm
                        company={company}
                        vendor={vendor}
                        invoiceDate={invoiceDate}
                        dueDate={dueDate}
                        invoiceNumber={invoiceNumber}
                        amount={amount}
                        currency={currency}
                        currencyOptions={['GBP', 'USD', 'EUR', 'TL', 'TRY']}
                        vendorOptions={vendors}
                        companyOptions={companies}
                        handleVendorChange={handleVendorChange}
                        handleCompanyChange={handleCompanyChange}
                        handleInvoiceNumberChange={handleInvoiceNumberChange}
                        handleInvoiceDateChange={handleInvoiceDateChange}
                        handleDueDateChange={handleDueDateChange}
                        handleCurrencyChange={handleCurrencyChange}
                        handleAmountChange={handleAmountChange}
                        handleSubmit={handleSubmit}
                        buttonText='Update'
                        marginLeft={'50px'}
                    />
                )}
                {role === 'Admin' && (
                    <Button type="primary" danger className="delete-button" onClick={handleDelete}>
                        Delete
                    </Button>
                )}
                <Modal
                    title=""
                    open={paidModalVisible}
                    onOk={() => { setPaidModalVisible(false); handleMarkAsPaid(); }}
                    onCancel={() => setPaidModalVisible(false)}
                >
                    <DatePicker
                        id="paid-date"
                        value={paidDate}
                        onChange={(date) => setPaidDate(date)}
                        required
                        format="DD/MM/YYYY"
                        style={datePickerStyle}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default InvoiceDetail;
