const folderVariables = {
    invoiceFolder: 'Finance & Treasury/1. WE SODA TREASURY/Invoice/INVOICE STORE (WE-KEW)',
    invoiceApprovalFolder: 'Finance & Treasury/1. WE SODA TREASURY/Invoice/INVOICE STORE (WE-KEW) - Verified',
    invoiceRejectedFolder: 'Finance & Treasury/1. WE SODA TREASURY/Invoice/INVOICE STORE (WE-KEW) - Rejected',
    invoicePaidFolder: 'Finance & Treasury/1. WE SODA TREASURY/Invoice/INVOICE STORE (WE-KEW) - Paid',
};

const folderVariablesSodaWorld = {
    invoiceFolder: 'Finance & Treasury/1. WE SODA TREASURY/Invoice/INVOICE STORE (SODA WORLD)',
    invoiceApprovalFolder: 'Finance & Treasury/1. WE SODA TREASURY/Invoice/INVOICE STORE (SODA WORLD) - Verified',
    invoiceRejectedFolder: 'Finance & Treasury/1. WE SODA TREASURY/Invoice/INVOICE STORE (SODA WORLD) - Rejected',
    invoicePaidFolder: 'Finance & Treasury/1. WE SODA TREASURY/Invoice/INVOICE STORE (SODA WORLD) - Paid',
};

export { folderVariables, folderVariablesSodaWorld };