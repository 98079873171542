import React from 'react';
import logo from '../../logo.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'; // Import the logout icon
import { useAuth } from '../../context/AuthContext';
import { useNavigate, Link  } from 'react-router-dom'; // Import useHistory hook from React Router
import { height } from '@fortawesome/free-solid-svg-icons/fa0';

const Header = ({ }) => {
    const { logout, user, role } = useAuth();
    const navigate = useNavigate();

    const headerStyle = {
        padding: "10px 0",
        lineHeight: "1.5em",
        backgroundColor: "#001f3f", // Navy background color
        color: "white",
        width: "100%",
    };

    const logoContainerStyle = {
        flex: 1,
        textAlign: "left",
        paddingLeft: "20px",
        top: "0",
    };

    const logoStyle = {
        height: "55px",
        width: "150px",
        display: "block",
        top: "0",
    };

    const userStyle = {
        flex: 1,
        textAlign: "right",
        paddingRight: "20px",
        top: "0",
        marginTop: "-60px",
        marginRight: "20px",
    };

    const userEmailStyle = {
        flex: 1,
        textAlign: "right",
        paddingRight: "20px",
        top: "0",
    };

    const logOutButtonStyle = {
        float: "right",
        marginTop: "-61px",
        marginRight: "5px",
        color: "navy",
    };

    const handleLogOut = () => {
        logout();
    }

    return (
        <header style={headerStyle}>
            <div className="logo" style={logoContainerStyle}>
                <Link to="/">
                    <img src={logo} alt="Logo" style={logoStyle} />
                </Link>
            </div>
            <div className="logout-button-container" style={logOutButtonStyle}>
                <button onClick={handleLogOut}>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                </button>
            </div>
            <div className="user" style={userStyle}>
                {user?.name} - {role}
            </div>
            <div className="userEmail" style={userEmailStyle}>
                {user?.username}
            </div>
        </header>
    );
};

export default Header;
