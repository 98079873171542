import { CosmosClient } from '@azure/cosmos';

// Azure Cosmos DB configuration
const endpoint = process.env.REACT_APP_COSMOS_DB_URI;
const key = process.env.REACT_APP_COSMOS_DB_KEY;
const databaseId = process.env.REACT_APP_COSMOS_DB_ID;
const containerId = 'invoices';

const client = new CosmosClient({ endpoint, key });

const database = client.database(databaseId);
const container = database.container(containerId);

const insertInvoice = async (invoiceData) => {
    try {
        const { resource } = await container.items.create(invoiceData);
        return resource;
    } catch (error) {
        console.error('Error inserting invoice data:', error);
        throw error;
    }
};

const getInvoicesPendingApproval = async () => {
    try {
        const querySpec = {
            query: 'SELECT * FROM c WHERE c.status IN (@status1, @status2)',
            parameters: [
                { name: '@status1', value: 'Pending' },
                { name: '@status2', value: 'Approved' },
            ],
        };

        const { resources } = await container.items.query(querySpec).fetchAll();
        return resources;
    } catch (error) {
        console.error('Error getting invoices pending approval:', error);
        throw error;
    }
};

const getInvoicesFirstApproval = async (firstApproverName) => {
    try {
        const querySpec = {
            query: 'SELECT * FROM c WHERE c.first_approver = @first_approver',
            parameters: [
                { name: '@first_approver', value: firstApproverName },
            ],
        };

        const { resources } = await container.items.query(querySpec).fetchAll();
        return resources;
    } catch (error) {
        console.error('Error getting invoices pending approval:', error);
        throw error;
    }
}

const getInvoices = async (sender) => {
    try {
        let querySpec;
        if (sender) {
            querySpec = {
                query: "SELECT * FROM c WHERE c.sender = @sender",
                parameters: [
                    { name: "@sender", value: sender }
                ]
            };
        } else {
            querySpec = {
                query: "SELECT * FROM c"
            };
        }

        const { resources } = await container.items.query(querySpec).fetchAll();
        return resources;
    } catch (error) {
        console.error('Error getting invoices:', error);
        throw error;
    }
};

const getInvoiceById = async (invoiceId) => {
    try {
        const { resource } = await container.item(invoiceId).read();
        return resource;
    } catch (error) {
        console.error('Error getting invoice:', error);
        throw error;
    }
};

const checkInvoiceExists = async (invoiceNumber, vendorId) => {
    try {
        const querySpec = {
            query: 'SELECT * FROM c WHERE c.invoice_number = @invoiceNumber AND c.vendor_id = @vendorId',
            parameters: [
                { name: '@invoiceNumber', value: invoiceNumber },
                { name: '@vendorId', value: vendorId },
            ],
        };

        const { resources } = await container.items.query(querySpec).fetchAll();
        return resources.length > 0;
    } catch (error) {
        console.error('Error checking invoice:', error);
        throw error;
    }
};

export const approveInvoice = async (invoiceId, financialController, financialControllerComment, status, agreementValue) => {
    try {
        const invoice = await getInvoiceById(invoiceId);
        invoice.approved_by = financialController;
        invoice.approval_comment = financialControllerComment;
        invoice.approved_date = new Date().toISOString();
        invoice.status = status;
        invoice.agreement = agreementValue === true ? 'Approved with Agreement' : 'Approved without Agreement';

        const { resource } = await container.item(invoiceId).replace(invoice);
        return resource;
    } catch (error) {
        console.error('Error approving invoice:', error);
        throw error;
    }
};

const updateInvoice = async (invoiceId, invoiceData) => {
    try {
        const invoice = await getInvoiceById(invoiceId);
        const updatedInvoice = { ...invoice, ...invoiceData };

        const { resource } = await container.item(invoiceId).replace(updatedInvoice);
        return resource;
    } catch (error) {
        console.error('Error updating invoice:', error);
        throw error;
    }
};

const deleteInvoice = async (invoiceId) => {
    try {
        await container.item(invoiceId).delete();

    } catch (error) {
        console.error('Error deleting invoice:', error);
        throw error;
    }
};

const rejectInvoice = async (invoiceId, financialController, financialControllerComment, status) => {
    try {
        const invoice = await getInvoiceById(invoiceId);
        invoice.rejected_by = financialController;
        invoice.approval_comment = financialControllerComment;
        invoice.approved_date = new Date().toISOString();
        invoice.status = status;

        const { resource } = await container.item(invoiceId).replace(invoice);
        return resource;
    } catch (error) {
        console.error('Error rejecting invoice:', error);
        throw error;
    }
};

const approveInvoiceFirstApprover = async (invoiceId, firstApprover, firstApproverComment, status) => {
    try {
        const invoice = await getInvoiceById(invoiceId);
        invoice.first_approver = firstApprover;
        invoice.first_approval_comment = firstApproverComment;
        invoice.first_approved_date = new Date().toISOString();
        invoice.status = status;

        const { resource } = await container.item(invoiceId).replace(invoice);
        return resource;
    } catch (error) {
        console.error('Error approving invoice:', error);
        throw error;
    }
};

const markInvoiceAsPaid = async (invoiceId, user, status, paidDate) => {
    try {
        const invoice = await getInvoiceById(invoiceId);
        invoice.paid_by = user;
        invoice.paid_date = paidDate;
        invoice.status = status;

        const { resource } = await container.item(invoiceId).replace(invoice);
        return resource;
    } catch (error) {
        console.error('Error marking invoice as paid:', error);
        throw error;
    }

}

export {
    insertInvoice, 
    getInvoices, 
    getInvoiceById, 
    checkInvoiceExists,
    updateInvoice, 
    deleteInvoice, 
    getInvoicesPendingApproval, 
    getInvoicesFirstApproval,
    rejectInvoice,
    approveInvoiceFirstApprover,
    markInvoiceAsPaid
};

