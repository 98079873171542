import React, { useState, useEffect } from 'react';
import { Table, Tag, Input } from 'antd';
// import { getVendors } from '../../api/NodeServer';
import { getVendors } from '../../api/VendorsContainer';

const { Search } = Input;

const VendorList = ({
    handleVendorClick
}) => {
    const [searchText, setSearchText] = useState('');
    const [sortedInfo, setSortedInfo] = useState({});
    const [vendorData, setVendorData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getVendors();
            setVendorData(data);
        };

        fetchData();
    }, []);

    const styles = `
    .container {
        flex: 1;
        margin-left: -30px;
        margin-top: 30px;
    }
    .ant-table-thead > tr > th {
        background-color: #f0f2f5;
    }
    .ant-table-row:hover {
        cursor: pointer; 
    }
`;
    const columns = [
        {
            title: 'Vendor Name',
            dataIndex: 'vendor',
            key: 'vendor',
            sorter: (a, b) => a.vendor.localeCompare(b.vendor),
            sortOrder: sortedInfo.columnKey === 'vendor' && sortedInfo.order,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => {
                // Provide default values for null or undefined types
                const typeA = a.type || '';
                const typeB = b.type || '';

                return typeA.localeCompare(typeB);
            },
            sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
        },
        {
            title: 'Recurring',
            dataIndex: 'recurring',
            key: 'recurring',
            render: (recurring) => (
                <Tag color={recurring ? 'green' : 'gray'}>
                    {recurring ? 'Yes' : 'No'}
                </Tag>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Tag color="red" key={record.id}>
                    Delete
                </Tag>
            ),

        }
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter); // Update sortedInfo state with the current sorting information
    };

    const filteredData = vendorData?.filter((vendor) =>
        Object.values(vendor).some((value) => {
            // Check if the value is null or undefined before converting it to a string
            if (value === null || value === undefined) {
                return false;
            }
            return value.toString().toLowerCase().includes(searchText.toLowerCase());
        })
    );

    return (
        <div className="container">
            <style>{styles}</style>
            <Search
                placeholder="Search Vendor"
                style={{ width: 200, marginBottom: 16 }}
                onChange={(e) => setSearchText(e.target.value)}
            />
            <Table
                columns={columns}
                dataSource={filteredData}
                onChange={handleTableChange}
                onRow={(record) => ({ onClick: () => handleVendorClick(record) })}
                rowKey="id"
            />
        </div>
    );
};

export default VendorList;
